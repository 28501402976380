<template>
  <base-layout-two :page-title="user.fullname" :page-default-back-link="`/users/${user.id}`" end-button-text="Edit"
    :end-button-url="`/users/${user.id}/profile/edit`">
    <section class="ion-padding background-white">
      <h1>Profile</h1>
    </section>

    <div class="padding-8">
      <ion-grid class="ion-no-padding grid-padding">
        <ion-row>
          <ion-col size="12">
            <base-card>
              <base-field-display label-text="Type" class="">
                <p class="ion-text-capitalize">
                  {{ user.role }}
                </p>
              </base-field-display>
            </base-card>
          </ion-col>

          <!-- Profile Information -->
          <ion-col size="12" v-if="user.role === 'mother'">
            <base-card title="⭐️ Profile Information">
              <ion-grid>
                <ion-row>
                  <!-- Name -->
                  <ion-col size="12" size-lg="4">
                    <base-field-display label-text="Name">
                      <p class="ion-text-capitalize">
                        {{ user.fullname }}
                      </p>
                    </base-field-display>
                  </ion-col>

                  <!-- Pronouns -->
                  <ion-col size="12" size-lg="4">
                    <base-field-display label-text="Pronouns">
                      <p class="ion-text-capitalize">
                        {{ user.profile.pronouns ? user.profile.pronouns : "" }}
                      </p>
                    </base-field-display>
                  </ion-col>

                  <!-- Preferred Name -->
                  <ion-col size="12" size-lg="4">
                    <base-field-display label-text="Preferred Name">
                      <p class="ion-text-capitalize">
                        {{ user.alias ? user.alias : "" }}
                      </p>
                    </base-field-display>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </base-card>
          </ion-col>

          <!-- Baby Profile Information -->
          <ion-col size="12" v-if="user.role === 'baby'">
            <base-card title="⭐️ Profile Information">
              <ion-grid>
                <ion-row>
                  <!-- Name -->
                  <ion-col size="12" size-lg="4">
                    <base-field-display label-text="Name">
                      <p class="ion-text-capitalize">
                        {{ user.fullname }}
                      </p>
                    </base-field-display>
                  </ion-col>

                  <!-- Pronouns -->
                  <ion-col size="12" size-lg="4">
                    <base-field-display label-text="Pronouns">
                      <p class="ion-text-capitalize">
                        {{ user.profile.pronouns }}
                      </p>
                    </base-field-display>
                  </ion-col>

                  <!-- Preferred Name -->
                  <ion-col size="12" size-lg="4">
                    <base-field-display label-text="Preferred Name">
                      <p class="ion-text-capitalize">
                        {{ user.alias ? user.alias : "" }}
                      </p>
                    </base-field-display>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col size="12" size-lg="3">
                    <base-field-display label-text="Due Date">
                      <p class="ion-text-capitalize" v-if="user.profile && user.profile.baby_due_date">
                        {{
                          formatDate(user.profile.baby_due_date, "MMM do, yyyy")
                        }}
                      </p>
                    </base-field-display>
                  </ion-col>

                  <ion-col size="12" size-lg="3">
                    <base-field-display label-text="Birth Date">
                      <p class="ion-text-capitalize" v-if="user.profile && user.profile.baby_due_date">
                        {{
                          formatDate(user.profile.birth_date, "MMM do, yyyy")
                        }}
                      </p>
                    </base-field-display>
                  </ion-col>

                  <ion-col size="12" size-lg="3">
                    <base-field-display label-text="Delivery Type">
                      <p class="ion-text-capitalize">
                        {{ user.profile.baby_delivery_type }}
                      </p>
                    </base-field-display>
                  </ion-col>

                  <ion-col size="12" size-lg="3">
                    <base-field-display label-text="Birth Weight">
                      <p class="">
                        {{ user.profile.baby_birth_weight }}
                      </p>
                    </base-field-display>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </base-card>
          </ion-col>

          <!-- Baby Feeding Information -->
          <ion-col size="12" v-if="user.role === 'baby'">
            <base-card title="🍼 Feeding">
              <ion-grid>
                <ion-row>
                  <ion-col size="12" size-lg="3">
                    <base-field-display label-text="Breast or Bottle">
                      <p class="ion-text-capitalize">
                        {{ user.profile.baby_feeding_method }}
                      </p>
                    </base-field-display>
                  </ion-col>

                  <ion-col size="12" size-lg="3">
                    <base-field-display label-text="Breastmilk or Formula">
                      <p class="ion-text-capitalize">
                        {{ user.profile.baby_feeding_source }}
                      </p>
                    </base-field-display>
                  </ion-col>

                  <ion-col size="12" size-lg="3">
                    <base-field-display label-text="Formula Brand">
                      <p class="ion-text-capitalize">
                        {{ user.profile.baby_feeding_formula }}
                      </p>
                    </base-field-display>
                  </ion-col>

                  <ion-col size="12" size-lg="3">
                    <base-field-display label-text="Schedule or On Demand">
                      <p class="ion-text-capitalize">
                        {{ user.profile.baby_feeding_schedule }}
                      </p>
                    </base-field-display>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </base-card>
          </ion-col>

          <!-- Baby Sleep Information -->
          <ion-col size="12" size-lg="12" v-if="user.role === 'baby'">
            <base-card title="💤 Nights">
              <ion-grid>
                <ion-row>
                  <ion-col size="12" size-lg="6">
                    <base-field-display label-text="Sleep Location">
                      <p class="ion-text-capitalize">
                        {{ user.profile.baby_night_sleep_location }}
                      </p>
                    </base-field-display>
                  </ion-col>

                  <ion-col size="12" size-lg="6">
                    <base-field-display label-text="Feeding Location">
                      <p class="ion-text-capitalize">
                        {{ user.profile.baby_night_feeding_location }}
                      </p>
                    </base-field-display>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </base-card>
          </ion-col>

          <!-- Baby Diapering Information -->
          <ion-col size="12" size-lg="6" v-if="user.role === 'baby'">
            <base-card title="🧦 Diaper">
              <ion-grid>
                <ion-row>
                  <ion-col size="12" size-lg="6">
                    <base-field-display label-text="Diapering">
                      <p class="ion-text-capitalize">
                        {{ user.profile.baby_diapering }}
                      </p>
                    </base-field-display>
                  </ion-col>

                  <ion-col size="12" size-lg="6">
                    <base-field-display label-text="Ointment">
                      <p class="ion-text-capitalize">
                        {{ user.profile.baby_ointment ? "Yes" : "No" }}
                      </p>
                    </base-field-display>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </base-card>
          </ion-col>

          <!-- Baby Other Information -->
          <ion-col size="12" size-lg="6" v-if="user.role === 'baby'">
            <base-card title="🧴 Other">
              <ion-grid>
                <ion-row>
                  <ion-col size="12" size-lg="6">
                    <base-field-display label-text="Clothing">
                      <p class="ion-text-capitalize">
                        {{ user.profile.baby_clothing }}
                      </p>
                    </base-field-display>
                  </ion-col>

                  <ion-col size="12" size-lg="6">
                    <base-field-display label-text="Pacifier">
                      <p class="ion-text-capitalize">
                        {{ user.profile.baby_pacifier ? "Yes" : "No" }}
                      </p>
                    </base-field-display>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </base-card>
          </ion-col>

          <!-- Care Information -->
          <ion-col size="12" v-if="user.role === 'mother'">
            <base-card title="❤️ Care">
              <ion-grid>
                <ion-row>
                  <!-- Due Date -->
                  <ion-col size="12" size-lg="6">
                    <base-field-display label-text="Due Date">
                      <p>
                        {{
                          user.profile.due_date ? formatDate(user.profile.due_date, "MMMM do, yyyy") : ""
                        }}
                      </p>
                    </base-field-display>
                  </ion-col>

                  <!-- Allergies -->
                  <ion-col size="12" size-lg="6">
                    <base-field-display label-text="Allergies">
                      <p>
                        {{
                          user.profile.allergies ? user.profile.allergies : ""
                        }}
                      </p>
                    </base-field-display>
                  </ion-col>

                  <!-- Dietary Preference -->
                  <ion-col size="12" size-lg="6">
                    <base-field-display label-text="Dietary Preference">
                      <p class="ion-text-capitalize">
                        {{
                          user.profile.dietary_preferences
                          ? user.profile.dietary_preferences
                          : ""
                        }}
                      </p>
                    </base-field-display>
                  </ion-col>

                  <!-- Prescribed Medications -->
                  <ion-col size="12" size-lg="6">
                    <base-field-display label-text="Prescribed Medications">
                      <p>
                        {{
                          user.profile.prescribed_medications
                          ? user.profile.prescribed_medications
                          : ""
                        }}
                      </p>
                    </base-field-display>
                  </ion-col>

                  <!-- OTC Medications -->
                  <ion-col size="12" size-lg="6">
                    <base-field-display label-text="OTC Medications">
                      <p>
                        {{
                          user.profile.otc_medications
                          ? user.profile.otc_medications
                          : ""
                        }}
                      </p>
                    </base-field-display>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </base-card>
          </ion-col>

          <!-- Contact Information -->
          <ion-col size="12" v-if="user.role === 'mother'">
            <base-card title="📞 Contact">
              <ion-grid>
                <ion-row>
                  <!-- Email -->
                  <ion-col size="12" size-lg="6">
                    <base-field-display label-text="Email">
                      <p>{{ user.email ? user.email : "" }}</p>
                    </base-field-display>
                  </ion-col>

                  <!-- Mobile Number -->
                  <ion-col size="12" size-lg="6">
                    <base-field-display label-text="Mobile Number">
                      <p>
                        {{ user.mobile_number ? user.mobile_number : "" }}
                      </p>
                    </base-field-display>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <!-- Support Person -->
                  <ion-col size="12" size-lg="6" v-if="user.role === 'mother'">
                    <base-field-display label-text="Support Person">
                      <p>
                        {{ user.profile.support_person_name ? user.profile.support_person_name : "" }}<br />
                        {{ user.profile.support_person_number ? user.profile.support_person_number : "" }}
                      </p>
                    </base-field-display>
                  </ion-col>

                  <!-- Emergency Contact -->
                  <ion-col size="12" size-lg="6" v-if="user.role === 'mother'">
                    <base-field-display label-text="Emergency Contact">
                      <p>
                        {{ user.profile.emergency_contact_name ? `${user.profile.emergency_contact_name}
                                                (${user.profile.emergency_contact_relationship ? user.profile.emergency_contact_relationship :
                            "relationship unknown"})` : ""
                        }}
                        <br />
                        {{ user.profile.emergency_contact_number ? user.profile.emergency_contact_number : "" }}
                      </p>
                    </base-field-display>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </base-card>
          </ion-col>

          <!-- Boram Anywhere -->
          <ion-col size="12" size-lg="12" v-if="user.role !== 'baby'">
            <base-card title="📱 Boram Anywhere">
              <ion-grid>
                <ion-row>
                  <ion-col size="6">
                    <base-field-display label-text="Start">
                      <p>
                        {{ user.profile.ba_start ? formatDate(user.profile.ba_start, "MMMM do, yyyy") : "" }}
                      </p>
                    </base-field-display>
                  </ion-col>
                  <ion-col size="6">
                    <base-field-display label-text="End">
                      <p>
                        {{ user.profile.ba_end ? formatDate(user.profile.ba_end, "MMMM do, yyyy") : "" }}
                      </p>
                    </base-field-display>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </base-card>
          </ion-col>

          <!-- OBGYN -->
          <ion-col size="12" size-lg="6" v-if="user.role !== 'baby'">
            <base-card title="OBGYN">
              <base-field-display label-text="OBGYN">
                <p>
                  {{ user.profile.obgyn_name ? user.profile.obgyn_name : "" }}<br />
                  {{ user.profile.obgyn_number ? user.profile.obgyn_number : "" }}
                </p>
              </base-field-display>
            </base-card>
          </ion-col>

          <!-- Pediatrician -->
          <ion-col size="12" size-lg="6" v-if="user.role === 'baby'">
            <base-card title="Pediatrician">
              <base-field-display label-text="Pediatrician">
                <p>
                  {{ user.profile.pediatrician_name ? user.profile.pediatrician_name : "" }}<br />
                  {{ user.profile.pediatrician_number ? user.profile.pediatrician_number : "" }}
                </p>
              </base-field-display>
            </base-card>
          </ion-col>

          <!-- General Notes -->
          <ion-col size="12">
            <base-card title="General Notes">
              <base-field-display label-text="General Notes">
                <p>
                  {{ user.general_notes ? user.general_notes : "" }}
                </p>
              </base-field-display>
            </base-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </base-layout-two>
</template>

<script>
import { IonGrid, IonRow, IonCol } from "@ionic/vue";
import { format } from "date-fns";
import { formatDate } from "@/util/helpers";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
  },
  data() {
    return {
      user: {
        profile: {},
      },
      format,
      formatDate,
    };
  },
  ionViewWillEnter() {
    this.fetchUser();
  },
  methods: {
    async fetchUser() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/users/${this.$route.params.id}`)
        .then((response) => {
          this.user = response.data.user;
        })
        .catch((error) => { console.log(error) });
    },
  },
};
</script>